import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type {
    LinksFunction,
    LoaderFunctionArgs,
    MetaFunction,
} from "@remix-run/node";
import { redirect } from "@remix-run/node";
import {
    isRouteErrorResponse,
    json,
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useRouteError,
} from "@remix-run/react";
import { getToast } from "remix-toast";
import { ToastContainer, toast as notify } from "react-toastify";

import "~/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "~/services/auth.server.tsx";
import { useEffect } from "react";
import { destroySession, getSession } from "~/services/sessions.server.tsx";

export const links: LinksFunction = () => [
    { rel: "stylesheet", href: "https://rsms.me/inter/inter.css" },
];

export const loader = async (args: LoaderFunctionArgs) => {
    const { toast, headers } = await getToast(args.request);
    const user = await getUser(args);
    const session = await getSession(args.request.headers.get("Cookie"));

    if (user === null && session.data.token) {
        return redirect("/", {
            headers: {
                "Set-Cookie": await destroySession(session),
            },
        });
    }

    return json(
        {
            env: {
                APP_NAME: process.env.APP_NAME,
            },
            toast,
            user: await getUser(args),
        },
        { headers },
    );
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
    return [{ title: data?.env.APP_NAME ?? "" }];
};

export const ErrorBoundary = () => {
    const error = useRouteError();
    captureRemixErrorBoundaryError(error);

    if (isRouteErrorResponse(error)) {
        return (
            <html className="antialiased h-full">
                <head>
                    <title>{error.statusText}</title>
                    <Meta />
                    <Links />
                </head>

                <body className="h-full">
                    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                        <div className="text-center">
                            <p className="text-base font-semibold text-orange-400">
                                {error.status}
                            </p>
                            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                {error.statusText}
                            </h1>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                {error.data
                                    ? error.data
                                    : "Sorry, we couldn't find the page you're looking for."}
                            </p>
                        </div>
                    </main>
                    <Scripts />
                </body>
            </html>
        );
    } else if (error instanceof Error) {
        return (
            <html className="antialiased h-full">
                <head>
                    <title>Error: {error.message}</title>
                    <Meta />
                    <Links />
                </head>

                <body className="h-full">
                    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                        <div className="text-center">
                            <p className="text-base font-semibold text-orange-400">
                                Error
                            </p>
                            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                Please try again later.
                            </h1>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                {error.message}
                            </p>
                            {process.env.NODE_ENV !== "production" && (
                                <div className="prose prose-primary text-left bg-gray-100 p-4 mt-6">
                                    <pre>
                                        <code>{error.stack}</code>
                                    </pre>
                                </div>
                            )}
                        </div>
                    </main>
                    <Scripts />
                </body>
            </html>
        );
    }

    return (
        <html className="antialiased h-full">
            <head>
                <title>Unknown Error</title>
                <Meta />
                <Links />
            </head>

            <body className="h-full">
                <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div className="text-center">
                        <p className="text-base font-semibold text-orange-400">
                            Error
                        </p>
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Please try again later.
                        </h1>
                    </div>
                </main>
                <Scripts />
            </body>
        </html>
    );
};

export default function App() {
    const { toast } = useLoaderData<typeof loader>();

    useEffect(() => {
        if (toast) {
            notify(toast.message, { type: toast.type });
        }
    }, [toast]);

    return (
        <html lang="en" className="antialiased h-full">
            <head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <Meta />
                <Links />
            </head>
            <body className="h-full dark:bg-slate-500">
                <Outlet />
                <ToastContainer hideProgressBar />
                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    );
}
